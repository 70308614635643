import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../layouts'
import SEO from '../components/SEO'
import PostList from '../components/PostList'
import Container from '../components/Container'

class BlogListPage extends React.Component {
  render () {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO
          title={this.props.data.site.siteMetadata.name + ' Blog'}
          article={false}
          description="URL Decoder Blog. Learn all about URL decoding. What is URL decoding, how to decode any URL encoded data back to normal text in various programming languages like Java, PHP, Python, Javascript, and Golang"
          pathname={this.props.location.pathname}
        />
        <Container>
          <PostList
            posts={posts}
            siteMeta={this.props.data.site.siteMetadata}
          />
        </Container>
      </Layout>
    )
  }
}

export default BlogListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            featured_image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
